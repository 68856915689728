import {CaseResponse} from "../../models/responses/Cases/CaseResponse";
import {CaseTabType, useCaseControl} from "../../pages/CaseDetails/useCaseControl.hook";
import {Grid, Tab, Tabs} from "@mui/material";
import React, {useContext, useMemo, useState} from "react";
import AuthenticationContext from "../../context/AuthenticationContext";
import {useCancel, useInviteSection3, usePurge} from "../../hooks/Cases/useMutateCase";
import CaseNotesView from "../common/CaseNotesView";
import AllActivityWidget from "../../pages/Home/AllActivityWidget";
import RehireModal from "./RehireModal";
import dayjs from "dayjs";
import {useCaseActivityLogList} from "../../hooks/Cases/useCaseActivityLogList";
import NameChangeModal from "./NameChangeModal";
import {TopBarActionButton, TopBarActionButtonSecondary} from './styles'
import AuthenticatedLink from "../common/AuthenticatedLink";
import {HeaderType} from "../../hooks/common/useAxiosParams";
import ReinviteCaseModal from "./ReinviteCaseModal";
import {ConfirmationModelContainer, useConfirmationModal} from "../ConfirmationModal";
import JourneyContext from "../../context/JourneyContext";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

const CaseDetailsForm = ({
                             emsCase,
                             i9PdfFile,
                             shareCodePdfFile,
                             isAuditorView
                         }: { emsCase: CaseResponse, i9PdfFile?: Blob, shareCodePdfFile?: Blob, isAuditorView?: boolean }) => {
    const {
        tabs,
        selectTab,
        tabSelected,
        TabPanel,
        interactions,
        handleCorrectionSectionOne,
        handleCorrectionSectionTwo,
        handleCompareSectionTwo,
        handleCompareSectionThree
    } = useCaseControl(emsCase);

    const {email, isAdminUser} = useContext(AuthenticationContext);
    const {currentJourney} = useContext(JourneyContext);
    const isI9 = currentJourney?.journeyType.startsWith("I9");
    const showInviteSection3 = emsCase.status === "SECTION_2_COMPLETE" && email.endsWith("@xydus.com");
    const {mutate, isLoading, isSuccess, isError} = useInviteSection3(emsCase.uid);
    const sendSection3Invite = mutate as () => void;

    const [isModalOpen, setModalOpen] = useState(false);
    const [isNameChangeModalOpen, setNameChangeModalOpen] = useState(false);
    const [isReinviteModalOpen, setReinviteModalOpen] = useState(false);

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        selectTab(newValue);
    };

    const handleCompareButtonClick = (e?: string) => {
        e === 'COMPARE_SECTION_2' ? handleCompareSectionTwo() : handleCompareSectionThree()
    }

    const showSectionOneCorrectionButton = useMemo(() => (emsCase.status === "SECTION_2_COMPLETE"
            && tabs.filter(i => i.key === CaseTabType.SECTION_2.toString()).length > 0)
        , [emsCase.status, tabs])

    const showSectionTwoCorrectionButton = useMemo(() => (["SECTION_2_COMPLETE", "SECTION_1_CORRECTION"].includes(emsCase.status)
            && tabs.filter(i => i.key === CaseTabType.SECTION_2.toString()).length > 0)
        , [emsCase.status, tabs])

    const caseEndDate = dayjs(emsCase.endDate);
    const todayDate = dayjs();
    const isBeforeTodayDate = caseEndDate.isBefore(todayDate, 'day');

    const showRehireButton = useMemo(() => (["SECTION_2_COMPLETE", "SECTION_3_COMPLETE"].includes(emsCase.status) && emsCase.endDate != null && isBeforeTodayDate
            && tabs.filter(i => i.key === CaseTabType.SECTION_2.toString()).length > 0)
        , [emsCase.status, tabs])

    const showRequestNameChangeButton = useMemo(() => (["SECTION_2_COMPLETE", "SECTION_3_COMPLETE"].includes(emsCase.status) && (emsCase.endDate == null || !isBeforeTodayDate)
            && tabs.filter(i => i.key === CaseTabType.SECTION_2.toString()).length > 0)
        , [emsCase.status, tabs])

    const showI9CancelButton = useMemo(() => isI9 && ("CANCELLED" !== emsCase.status), [emsCase.status]);

    const purgeApplicableStatuses: string[] | undefined = currentJourney && Object.entries(currentJourney?.uiConfig.filter)
        .map(([key, value]) => ({key: key, statuses: value.statuses}))
        .find(item => (item.key === "Purge_Applicable"))?.statuses;
    const showDeleteCaseButton = useMemo(() => (isI9 && isAdminUser && purgeApplicableStatuses?.includes(emsCase.status))
        , [emsCase.status]);

    const {data: activityLogs} = useCaseActivityLogList(emsCase.uid);

    const reinviteInteraction = interactions.find(itm => itm.interactionType === "REINVITE");
    const cancelInteraction = interactions.find(itm => itm.interactionType === "CANCEL");
    const cancelConfirmationScreen = cancelInteraction?.screens.find(screen => screen.type === "confirmation");
    const cancelSuccessMessage = cancelInteraction?.screens.find(screen => screen.type === "success")?.title || "This case has been cancelled.";
    const modal = useConfirmationModal();
    const {mutate: mutateCancel} = useCancel(emsCase.uid, (callback: () => void) => {
        toast.success(cancelSuccessMessage);
        callback();
    });
    const navigate = useNavigate();
    const {mutate: deleteCases} = usePurge(
        () => {
            toast.success("Case is deleted");
            setTimeout(() => navigate("/cases"), 3000);
        },
        () => {
            toast.error("Case deletion is failed");
        },
    );

    const handleDeleteCase = () => {
        deleteCases({purgeCaseIds: [emsCase.uid]});
    }

    const downloadInteraction = interactions.find(itm => itm.interactionType === "DOWNLOAD");

    const handleCancelRequest = () => {
        cancelConfirmationScreen && modal.openConfirmationModal({
            message: cancelConfirmationScreen.title,
            content: cancelConfirmationScreen.description,
            confirmButtonLabel: cancelConfirmationScreen.confirmButtonLabel ? cancelConfirmationScreen.confirmButtonLabel : "Confirm",
            cancelButtonLabel: cancelConfirmationScreen.cancelButtonLabel,
            onConfirm: mutateCancel,
            onCancel: () => undefined
        })
    }

    const handleDeleteRequest = () => {
        modal.openConfirmationModal({
            message: "By clicking ‘Yes’, you are authorizing the deletion of this case record.",
            confirmButtonLabel: "Yes",
            cancelButtonLabel: "No",
            onConfirm: handleDeleteCase,
            onCancel: () => undefined
        });
    }

    const handleI9CancelRequest = () => {
        modal.openConfirmationModal({
            message: "By clicking ‘Yes’, you are authorizing the cancellation of this case record.",
            confirmButtonLabel: "Yes",
            cancelButtonLabel: "No",
            onConfirm: mutateCancel,
            onCancel: () => undefined
        });
    }

    return (
        <Grid
            container
            sx={{height: '100%'}}
            flexDirection={"row"}
            alignItems={"flex-start"}
            justifyItems={"flex-start"}
        >
            <Grid
                container
                alignItems={"center"}
                item
                xs={12}
                sx={{borderBottom: 1, borderColor: "divider"}}
            >
                <Grid item xs={isI9 ? 5 : 8}>
                    <Tabs
                        value={tabSelected?.key}
                        onChange={handleTabChange}
                        sx={{margin: 1, zoom: 0.92}}
                        variant="scrollable"
                        scrollButtons="auto"
                        TabIndicatorProps={{
                            style: {
                                backgroundColor: "#000",
                            },
                        }}
                    >
                        {tabs.map((tab) => (
                            <Tab
                                key={`tab-${tab.key}`}
                                label={tab.label}
                                value={tab.key}
                                sx={{color: "#333!important"}}
                            />
                        ))}
                    </Tabs>
                </Grid>
                <Grid container item justifyContent={"flex-end"} xs={isI9 ? 7 : 4}
                      style={{flexGrow: 1, padding: '0 20px'}}>
                    {!isAuditorView && <>
                        <TopBarActionButton variant="contained"
                                            style={{display: showRehireButton ? "" : "none"}}
                                            disabled={!showRehireButton || isLoading || isError || isSuccess}
                                            onClick={() => setModalOpen(!isModalOpen)}>
                            Rehire
                        </TopBarActionButton>
                        <TopBarActionButton variant="contained"
                                            style={{display: showRequestNameChangeButton ? "" : "none"}}
                                            disabled={!showRequestNameChangeButton || isLoading || isError || isSuccess}
                                            onClick={() => setNameChangeModalOpen(!isNameChangeModalOpen)}>
                            Request Name Change
                        </TopBarActionButton>
                        <TopBarActionButton variant="contained"
                                            style={{display: showInviteSection3 ? "" : "none"}}
                                            disabled={!showInviteSection3 || isLoading || isError || isSuccess}
                                            onClick={sendSection3Invite}>
                            Send Supplement B Invite
                        </TopBarActionButton>
                        <TopBarActionButton variant="contained"
                                            style={{display: showSectionTwoCorrectionButton ? "" : "none"}}
                                            disabled={!showSectionTwoCorrectionButton || isBeforeTodayDate}
                                            onClick={handleCorrectionSectionTwo}>
                            Correction (Section 2)
                        </TopBarActionButton>
                        <TopBarActionButton variant="contained"
                                            style={{display: showSectionOneCorrectionButton ? "" : "none"}}
                                            disabled={!showSectionOneCorrectionButton || isBeforeTodayDate}
                                            onClick={handleCorrectionSectionOne}>
                            Correction (Section 1)
                        </TopBarActionButton>
                        <TopBarActionButtonSecondary variant="contained"
                                                     style={{display: showDeleteCaseButton ? "" : "none"}}
                                                     disabled={!showDeleteCaseButton}
                                                     onClick={handleDeleteRequest}>
                            Delete Case
                        </TopBarActionButtonSecondary>
                        {
                            showI9CancelButton && <TopBarActionButtonSecondary
                                variant="contained"
                                onClick={() => handleI9CancelRequest()}>
                                Cancel
                            </TopBarActionButtonSecondary>
                        }
                        {
                            cancelInteraction && <TopBarActionButtonSecondary
                                variant="contained"
                                onClick={() => handleCancelRequest()}>
                                {cancelInteraction.buttonLabel}
                            </TopBarActionButtonSecondary>
                        }
                        {
                            reinviteInteraction && <TopBarActionButton
                                variant="contained"
                                onClick={() => setReinviteModalOpen(!isReinviteModalOpen)}>
                                {reinviteInteraction.buttonLabel}
                            </TopBarActionButton>
                        }
                        {
                            downloadInteraction && <AuthenticatedLink
                                url={`/${emsCase.uid}`}
                                headerType={HeaderType.DOWNLOAD_PDF}
                                fileName={`${emsCase.givenName}-${emsCase.familyName}-${emsCase.consumerReference}-${new Date().toISOString().replaceAll(":", "-").replaceAll(".", "-")}.pdf`}
                            ><TopBarActionButton
                                variant="contained">
                                {downloadInteraction.buttonLabel}
                            </TopBarActionButton></AuthenticatedLink>
                        }
                    </>}
                </Grid>
            </Grid>
            <Grid
                container
                item
                xs={9.45}
                sx={{height: 'calc(100% - 70px)', padding: 3}}
            >
                {TabPanel && tabSelected && <TabPanel data={tabSelected.data}
                                                      context={tabSelected.context}
                                                      prevData={tabSelected.prevData}
                                                      i9PdfFile={i9PdfFile}
                                                      shareCodePdfFile={shareCodePdfFile}
                                                      canReplaceShareCodePdf={["APPROVAL_REQUIRED_WITH_UPLOADED_SHARECODE", "EXCEPTION_HANDLING_FOR_SHARECODE"].includes(emsCase.status)}
                                                      handleRefresh={tabSelected.handleRefresh}
                                                      images={tabSelected.images}
                                                      handleCompare={(e) => handleCompareButtonClick(e)}/>}
            </Grid>
            <Grid
                container
                item
                xs={2.55}
                sx={{
                    height: "calc(100vh - 125px)",
                    borderLeft: 1,
                    borderColor: "divider",
                    padding: 1
                }}
            >
                {!isAuditorView && activityLogs && activityLogs.length > 0 &&
                    <div>
                        <Grid container alignContent={"flex-start"} item padding={1}>
                            <Grid item xs={9} style={{fontWeight: 600, marginBottom: 5}}>Activity</Grid>
                            <div style={{maxHeight: 350, overflow: "auto"}}>
                                <AllActivityWidget
                                    activityLogs={activityLogs}/>
                            </div>
                        </Grid>
                        <div style={{borderBottom: "1px solid #D7D7D7", width: "100%"}}/>
                    </div>
                }
                <CaseNotesView caseId={emsCase.uid}/>
            </Grid>
            <RehireModal isOpen={isModalOpen} onClose={() => setModalOpen(!isModalOpen)} caseId={emsCase.uid}/>
            <NameChangeModal isOpen={isNameChangeModalOpen}
                             onClose={() => setNameChangeModalOpen(!isNameChangeModalOpen)} caseId={emsCase.uid}/>
            <ReinviteCaseModal isOpen={isReinviteModalOpen} onClose={() => setReinviteModalOpen(!isReinviteModalOpen)}
                               caseId={emsCase.uid} screen={reinviteInteraction?.screens[0]}/>
            <ConfirmationModelContainer {...modal}/>
        </Grid>
    );
};

export default CaseDetailsForm;
