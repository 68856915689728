import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import { DetailsBlock } from "../common";
import { StartEndDateField } from "../BasicDetailsPanel/styles";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../pages/CreateCase/CreateCaseForm/styles";
import { useRehire } from "../../../hooks/Cases/useMutateCase";

type RehireModalProps = {
  isOpen: boolean;
  onClose: () => void;
  caseId: string;
};

type RehireDataProps = {
  rehireDate: string;
  isEmployer: boolean;
};

const RehireModal = ({ isOpen, onClose, caseId }: RehireModalProps) => {
  const { mutate, isLoading } = useRehire(caseId);

  const [rehireData, setRehireData] = useState<RehireDataProps>({
    rehireDate: "",
    isEmployer: false,
  });

  // eslint-disable-next-line
  const handleRehireDateChange = (date: any) => {
    setRehireData({
      ...rehireData,
      rehireDate: new Date(date).toLocaleDateString("sv-SE") + "T00:00:00.000Z",
    });
  };

  const handleRehire = () => {
    mutate(rehireData);
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth={"sm"} fullWidth>
      <DialogTitle sx={{ fontWeight: "bold" }}>Rehire</DialogTitle>
      <DialogContent>
        <p
          style={{
            fontSize: "14px",
            borderBottom: "1px solid #cecece",
            padding: "0 0 20px 0",
            marginBottom: "20px",
          }}
        >
          This will move the case to ‘Supplement B’, and send a
          notification to the candidate to complete Supplement B of Form I-9.
        </p>
        <Grid container>
          <DetailsBlock xs={12} title={"Profile"}>
            <Grid container alignItems={"center"}>
              <Grid item xs={5}>
                Start Date:
              </Grid>
              <Grid item xs={7}>
                <StartEndDateField onAccept={handleRehireDateChange} />
              </Grid>
            </Grid>
            <Grid container alignItems={"center"} sx={{ marginTop: "20px" }}>
              <Grid item xs={5}>
                Authorized Representative
              </Grid>
              <Grid item xs={7}>
                <RadioGroup
                  row
                  aria-labelledby="rehire-aithorized-rep"
                  name="rehire-aithorized-rep"
                  defaultValue={true}
                  onChange={(e) =>
                    setRehireData({
                      ...rehireData,
                      isEmployer: /false/.test(e.target.value),
                    })
                  }
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              style={{ flexGrow: 1, padding: 1, marginTop: "30px" }}
            >
              <Grid
                container
                item
                xs={12}
                alignItems={"center"}
                justifyContent={"flex-end"}
              >
                <SecondaryButton variant="contained" onClick={onClose}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                  disabled={!rehireData.rehireDate || isLoading}
                  onClick={handleRehire}
                >
                  Submit
                </PrimaryButton>
              </Grid>
            </Grid>
          </DetailsBlock>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default RehireModal;
