import {useContext, useEffect} from "react";
import AuthenticationContext from "../../context/AuthenticationContext";

import { Grid, Paper } from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";

import HeaderBar from "../../components/HeaderBar";
import DateNameWidget from "./DateNameWidget";
import DashboardBox from "./DashboardBox";
import CaseFinderWidget from "./CaseFinderWidget";
import SummaryWidget from "./SummaryWidget";
import EverifyWidget from "./EverifyWidget";
import SectionsDataWidget from "./SectionsDataWidget";
import SectionRTWDataWidget from "./SectionRTWDataWidget";
import AllActivityWidget from "./AllActivityWidget";
import LocationMap from "./LocationMap";
import { DashboardActionButton, BlankDashboardCard, RTWTilesGrid } from "./styles";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useActivityLogList} from "../../hooks/Audit/useActivityLogList";
import {useParams} from "react-router-dom";
import {useSaveOAuthToken} from "../../hooks/Meetings/useSaveOAuthToken";
import { useBrand } from "../../hooks/Brand/useBrand";
import JourneyContext from "../../context/JourneyContext";

const Home = () => {
  const { provider } : { provider? : string} = useParams();
  const [ params ] = useSearchParams();
  const oauthCode = params?.get("code");

  const navigate = useNavigate();

  const uploadButtonHandler = () => {
    navigate("/cases/new", { state: { caseCreateType: "batch" } });
  };
  const createButtonHandler = () => {
    navigate("/cases/new", { state: { caseCreateType: "single" } });
  };

  const {firstname} = useContext(AuthenticationContext);
  const {currentJourney} = useContext(JourneyContext);
  const {data: activityLogs} = useActivityLogList(currentJourney?.uid || "");

  const { mutate : saveToken } = useSaveOAuthToken();

  const {data : brand} = useBrand();
  const isEverifyEnabled = brand?.i9Config?.everifyEnabled;

  useEffect(() => {
    if (provider && oauthCode) {
      saveToken({
        code: oauthCode,
        provider: provider,
      })
    }
  }, [provider, oauthCode])

  return (
      <HeaderBar breadcrumbs={[{ label: "Dashboard" }]}>
        <Grid container spacing={2} sx={{ p: 3 }}>
          <Grid item xs={9}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <DateNameWidget name={firstname} />
              </Grid>
              <DashboardBox title="Case Finder">
                <CaseFinderWidget />
              </DashboardBox>
              <DashboardBox title="Summary">
                <SummaryWidget />
              </DashboardBox>
              {
                currentJourney && currentJourney.journeyType.startsWith("I9") && isEverifyEnabled &&
                  <DashboardBox title="E-Verify">
                    <EverifyWidget />
                  </DashboardBox>
              }
              {currentJourney && currentJourney.uiConfig?.dashboard  && !currentJourney.journeyType.startsWith("I9") &&
                <Grid item xs={4}>
                  <BlankDashboardCard>
                    <Paper>
                  {currentJourney && currentJourney?.uiConfig?.dashboard?.filter(item => item.id === "approved_recent").map((tile) => (
                          tile.tiles.map((item, index) => (
                              <SectionRTWDataWidget tile={item} key={index}/>
                          ))
                          ))}
                    </Paper>
                  </BlankDashboardCard>
                </Grid>
              }
              <Grid item xs={currentJourney?.uiConfig?.dashboard? 8 : 12}>
                <LocationMap />
              </Grid>
              <Grid item xs={12}>
                <RTWTilesGrid>
                  {currentJourney && currentJourney?.uiConfig?.dashboard?.filter(item => item.id !== "approved_recent").map((tile) => (
                    <>
                      {tile.tiles.map((item, index) => (
                        <BlankDashboardCard key={index}>
                          <Paper>
                            <SectionRTWDataWidget tile={item} key={index}/>
                          </Paper>
                        </BlankDashboardCard>
                      ))}
                    </>
                  ))}
                </RTWTilesGrid>
              </Grid>
              {currentJourney && currentJourney.journeyType.startsWith("I9") &&
              <>
                <DashboardBox title="Section 1">
                  <SectionsDataWidget section="Section_1_In_Progress" />
                </DashboardBox>
                <DashboardBox title="Section 2">
                  <SectionsDataWidget section="Section_2_In_Progress" />
                </DashboardBox>
                <DashboardBox title="Supplement B">
                  <SectionsDataWidget section="Section_3_In_Progress" />
                </DashboardBox>
              </>
              }
            </Grid>
          </Grid>

          <Grid item xs={3}>
            <DashboardActionButton
                startIcon={<UploadIcon />}
                onClick={uploadButtonHandler}
            >
              Upload new cases
            </DashboardActionButton>
            <DashboardActionButton
                startIcon={<AddBoxOutlinedIcon />}
                onClick={createButtonHandler}
            >
              Create new case
            </DashboardActionButton>
            <DashboardBox size={12}  height="calc(100% - 140px)" title="All Activity">
              <AllActivityWidget activityLogs={activityLogs}/>
            </DashboardBox>
          </Grid>
        </Grid>
      </HeaderBar>
  );
};

export default Home;
