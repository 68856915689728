import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import { DetailsBlock } from "../common";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../pages/CreateCase/CreateCaseForm/styles";
import { useNameChange } from "../../../hooks/Cases/useMutateCase";

type NameChangeModalProps = {
  isOpen: boolean;
  onClose: () => void;
  caseId: string;
};

type NameChangeDataProps = {
  isEmployer: boolean;
};

const NameChangeModal = ({ isOpen, onClose, caseId }: NameChangeModalProps) => {
  const { mutate, isLoading } = useNameChange(caseId);

  const [nameChangeData, setNameChangeData] = useState<NameChangeDataProps>({
    isEmployer: false,
  });

  const handleNameChange = () => {
    mutate(nameChangeData);
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth={"sm"} fullWidth>
      <DialogTitle sx={{ fontWeight: "bold" }}>Request Name Change</DialogTitle>
      <DialogContent>
        <p
          style={{
            fontSize: "14px",
            borderBottom: "1px solid #cecece",
            padding: "0 0 20px 0",
            marginBottom: "20px",
          }}
        >
          This will move the case to ‘Supplement B - Rename’, and send a
          notification to the candidate to update Supplement B given name and family name.
        </p>
        <Grid container>
          <DetailsBlock xs={12}>
            <Grid container alignItems={"center"} sx={{ marginTop: "0px" }}>
              <Grid item xs={5}>
                Authorized Representative
              </Grid>
              <Grid item xs={7}>
                <RadioGroup
                  row
                  aria-labelledby="rehire-aithorized-rep"
                  name="rehire-aithorized-rep"
                  defaultValue={true}
                  onChange={(e) =>
                    setNameChangeData({
                      ...nameChangeData,
                      isEmployer: /false/.test(e.target.value),
                    })
                  }
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              style={{ flexGrow: 1, padding: 1, marginTop: "30px" }}
            >
              <Grid
                container
                item
                xs={12}
                alignItems={"center"}
                justifyContent={"flex-end"}
              >
                <SecondaryButton variant="contained" onClick={onClose}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                  disabled={isLoading}
                  onClick={handleNameChange}
                >
                  Submit
                </PrimaryButton>
              </Grid>
            </Grid>
          </DetailsBlock>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default NameChangeModal;
