import { styled, CSSObject } from "@mui/material/styles";
import { Alert, Button, ButtonProps, Chip, Paper, TableCell, TableRow } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { tableCellClasses } from "@mui/material/TableCell";

export const CaseProfileBox = styled(Paper)(
  ({ theme }): CSSObject => ({
    background: theme.palette.primary.light,
    borderRadius: "20px",
    padding: "30px 0px",
    textAlign: "center",
  })
);

export const CaseDetailsBox = styled(Paper)(() => ({
  background: "#fff",
  borderRadius: "20px",
  padding: "10px 20px",
  border: "1px solid #D7D7D7",
  p: {
    margin: 0,
    color: "#666666",
    span: {
      color: "#1A1A1A",
    },
  },
}));

export const SummaryBox = styled(Paper)(() => ({
  background: "#fff",
  borderRadius: "10px",
  padding: "10px 20px",
  border: "1px solid #D7D7D7",
}));

export const EverifyDetailsBox = styled(Paper)(() => ({
  background: "#fff",
  width: "90%",
  margin: "0px auto",
  padding: "15px 30px",
  borderRadius: "10px",
  p: {
    textAlign: "left",
    margin: 0,
    strong: {
      fontWeight: "bold",
      marginBottom: "5px",
      display: "block",
    },
  },
}));

export const ExpirationDateBadge = styled(Chip)(() => ({
  padding: "20px 0px",
  borderRadius: "80px",
  width: "90%",
  "&.MuiChip-colorWarning": {
    background: "#E1B080",
    color: "#1A1A1A",
  },
  "&.MuiChip-colorError": {
    background: "#E58181",
    color: "#1A1A1A",
  },
}));

export const StartEndDateField = styled(DatePicker)(() => ({
  float: "right",
  margin: "6px 0",
  width: "100%",
  borderRadius: "8px",
  "& .MuiOutlinedInput-root": {
    background: "#F4FDFC",
    borderRadius: "8px",
    "& .MuiInputBase-input": {
      padding: "10px 14px",
    },
  },
}));

export const StatusSummaryTableRow = styled(TableRow)(() => ({
  "& td": {
    borderBottom: 0,
  },
}));

export const StatusSummaryTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.root}`]: {
    color: "#333",
    fontSize: 16,
    borderRight: "1px solid #D7D7D7",
    padding: "6px 16px",
  },
  [`&:last-of-type.${tableCellClasses.root}`]: {
    borderRight: 0,
  },
}));

export const AlternativeProcedureBox = styled(Paper)(
  (): CSSObject => ({
    border: '2px solid #EBE790',
    borderRadius: '14px',
    background: '#fcfbef',
    padding: '20px 30px',
    '&.supb-box': {
      border: '2px solid #8D6FE5',
      background: '#eee9fb'
    },
    '.MuiTypography-body1': {
      fontSize: '18px'
    },
    'p': {
      fontSize: '14px',
      lineHeight: '24px'
    }
  })
)

export const RequestOptOutFromSection3Box = styled(Paper)(
    (): CSSObject => ({
        border: '2px solid #9CAFF3',
        borderRadius: '14px',
        background: '#9CAFF326',
        padding: '20px 30px',
        '.MuiTypography-body1': {
            fontSize: '18px'
        },
        'p': {
            fontSize: '14px',
            lineHeight: '24px'
        }
    })
)

export const RequestOptOutFromSection2Box = styled(Paper)(
    (): CSSObject => ({
        border: '2px solid #E1B080',
        borderRadius: '14px',
        background: '#E1B08026',
        padding: '20px 30px',
        '.MuiTypography-body1': {
            fontSize: '18px'
        },
        'p': {
            fontSize: '14px',
            lineHeight: '24px'
        }
    })
)

export const AuthenticatedAccountAlert = styled(Alert)(
  ({ theme }): CSSObject => ({
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: "8px",
    marginTop: '20px',
    background: '#fff',
    color: theme.palette.primary.main,
    'svg': {
      'path': {
        fill: theme.palette.primary.main,
      }
    },
    '.MuiTypography-body1': {
      fontSize: '16px',
      fontWeight: 'bold'
    }
  })
)

export const VerifyDocumentsButton = styled(Button)<ButtonProps>(
  ({ theme }): CSSObject => ({
    color: "#ffffff",
    backgroundColor: theme.palette.primary.main,
    textTransform: "none",
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "10px",
    fontSize: "12px",
    padding: "5px 15px",
    margin: "5px 0px 5px 0px",
    'svg': {
      fontSize: '10px',
      marginLeft: '10px',
    },
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.main,
    },
    "&.Mui-disabled": {
      background: "#cecece",
      border: `1px solid #cecece`,
    },
  })
);
