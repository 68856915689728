import { useState } from "react";
import { CircularProgress, Grid, Stack, Typography } from "@mui/material";
import DocumentImage from "../../../components/DocumentImage";

type DocumentListProps = {
  images: [
    {
      title: string;
      image: string;
    }
  ];
  isLoading: boolean;
};

const DocumentList = ({ images, isLoading }: DocumentListProps) => {
  const [documentSrc, setDocumentSrc] = useState("");

  if (isLoading) return <CircularProgress />;

  return (
    <Grid container sx={{ padding: 2 }}>
      <Grid item xs={12}>
        <Typography sx={{ fontWeight: "bold", marginBottom: "15px" }}>
          Documentation received from employee
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" spacing={2}>
          {images.map(
            (item: { title: string; image: string }, index: number) => (
              <img
                src={item.image}
                key={index}
                aria-label={item.title}
                style={{
                  display: "block",
                  width: "100px",
                  cursor: "pointer",
                  borderRadius: "4px",
                  padding: "4px",
                  border:
                    documentSrc === item.image ? "2px solid #1CDBBC" : "none",
                }}
                onClick={() => setDocumentSrc(item.image)}
              />
            )
          )}
        </Stack>
      </Grid>
      <Grid item xs={6}>
        <DocumentImage imageScr={documentSrc || images[0].image} />
      </Grid>
    </Grid>
  );
};

export default DocumentList;
