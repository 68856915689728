import { useContext } from "react";
import { Chip, List, ListItem, Stack } from "@mui/material";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { Flag, FlagOutlined, FlagTwoTone } from "@mui/icons-material";
import {
  EverifyDoughnutChart,
  EverifyListButton,
  EverifyStack,
} from "./styles";
import { Link } from "react-router-dom";
import AuthenticationContext from "../../../context/AuthenticationContext";
import {
  CaseListFilterContext
} from "../../../context/CaseListFilters";
import { useCaseList } from "../../../hooks/Cases/useCaseList";
import JourneyContext from "../../../context/JourneyContext";

const EverifyWidget = () => {
  const { defaultBrandId } = useContext(AuthenticationContext);
  const { currentJourney } = useContext(JourneyContext);
  const { everifyCaseCount } = useCaseList(currentJourney?.brandId || defaultBrandId);
  const count = everifyCaseCount();

  const { setFilterStatuses, setFilterDate, setFilterDateEnd, setFilterText } = useContext(
    CaseListFilterContext
  );

  const handleCaseLink = (filters: string[]) => () => {
    setFilterStatuses(filters);
    setFilterText("");
    setFilterDate("");
    setFilterDateEnd("");
  };

  return (
    <>
      <EverifyDoughnutChart>{count.totalCount}</EverifyDoughnutChart>
      <List>
        <ListItem disablePadding dense>
          <Link
            to={`/cases`}
            onClick={handleCaseLink(["FINAL_NONCONFIRMATION"])}
            underline="none"
            style={{
              color: "#000",
              width: "100%",
              display: "block",
              textDecoration: "none",
            }}
          >
            <EverifyListButton>
              <EverifyStack direction="row" useFlexGap flexWrap="wrap">
                <Stack direction="row" useFlexGap flexWrap="wrap">
                  <Flag />
                  <p>Final Nonconfirmation</p>
                </Stack>
                <Stack direction="row" useFlexGap flexWrap="wrap">
                  <Chip
                    label={count.finalNonConfirmation || 0}
                    variant="outlined"
                    className="nonconfirmation"
                  />
                  <ArrowForwardIosOutlinedIcon fontSize="small" />
                </Stack>
              </EverifyStack>
            </EverifyListButton>
          </Link>
        </ListItem>
        <ListItem disablePadding dense>
          <Link
            to={`/cases`}
            onClick={handleCaseLink(["TENTATIVE_NONCONFIRMATION"])}
            underline="none"
            style={{
              color: "#000",
              width: "100%",
              display: "block",
              textDecoration: "none",
            }}
          >
            <EverifyListButton>
              <EverifyStack direction="row" useFlexGap flexWrap="wrap">
                <Stack direction="row" useFlexGap flexWrap="wrap">
                  <FlagTwoTone /> <p>Tentative Nonconfirmation</p>
                </Stack>
                <Stack direction="row" useFlexGap flexWrap="wrap">
                  <Chip
                    label={count.tentativeNonConfirmation || 0}
                    variant="outlined"
                    className="tentativeconfirmation"
                  />
                  <ArrowForwardIosOutlinedIcon fontSize="small" />
                </Stack>
              </EverifyStack>
            </EverifyListButton>
          </Link>
        </ListItem>
        <ListItem disablePadding dense>
          <Link
            to={`/cases`}
            onClick={handleCaseLink(["EMPLOYMENT_AUTHORIZED"])}
            underline="none"
            style={{
              color: "#000",
              width: "100%",
              display: "block",
              textDecoration: "none",
            }}
          >
            <EverifyListButton>
              <EverifyStack direction="row" useFlexGap flexWrap="wrap">
                <Stack direction="row" useFlexGap flexWrap="wrap">
                  <FlagOutlined />
                  <p>Employment Authorized</p>
                </Stack>
                <Stack direction="row" useFlexGap flexWrap="wrap">
                  <Chip
                    label={count.employmentAuthorized || 0}
                    variant="outlined"
                    className="authorized"
                  />
                  <ArrowForwardIosOutlinedIcon fontSize="small" />
                </Stack>
              </EverifyStack>
            </EverifyListButton>
          </Link>
        </ListItem>
        <ListItem disablePadding dense>
          <Link
              to={`/cases`}
              onClick={handleCaseLink(["CASE_CREATION_FAILED"])}
              underline="none"
              style={{
                color: "#000",
                width: "100%",
                display: "block",
                textDecoration: "none",
              }}
          >
            <EverifyListButton>
              <EverifyStack direction="row" useFlexGap flexWrap="wrap">
                <Stack direction="row" useFlexGap flexWrap="wrap">
                  <Flag style={{ fill: 'rgba(255, 0, 0, 0.5)'}}/>
                  <p>Case Creation Failed</p>
                </Stack>
                <Stack direction="row" useFlexGap flexWrap="wrap">
                  <Chip
                      label={count.caseCreationFailed || 0}
                      variant="outlined"
                      className="creationfailed"
                  />
                  <ArrowForwardIosOutlinedIcon fontSize="small" />
                </Stack>
              </EverifyStack>
            </EverifyListButton>
          </Link>
        </ListItem>
      </List>
    </>
  );
};

export default EverifyWidget;
