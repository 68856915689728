import React, { createContext, useCallback, useMemo, useState } from "react";
import { JourneyInfo } from "../models/responses/JourneyInfo";
import { useJourneys } from "../hooks/Journeys/useJourneys";
import {
    createTheme,
    LinearProgress,
    Shadows,
    ThemeOptions,
    ThemeProvider,
    Typography,
  } from "@mui/material";
  import "../styles/global.css";

interface JourneyContextInterface {
    currentJourney? : JourneyInfo;
    journeys?: JourneyInfo[];
    selectJourney : (journeyId: string) => void;
}

const JourneyContext = createContext<JourneyContextInterface>({
    currentJourney: undefined,
    journeys: undefined,
    selectJourney: () => undefined,
})

export const JourneyContextProvider = ({
    children,
  }: {
    children: React.ReactNode;
  }) => {
    const { data : journeys, isError } = useJourneys();
    const [currentJourneyId, setCurrentJourneyId] = useState<string | undefined>(sessionStorage.getItem("journeyId") || undefined);

    const currentJourney = useMemo(()=>{
        if (!journeys || journeys.length === 0) {
            return undefined;
        }
        return journeys.find(journey => journey.uid === currentJourneyId) || journeys[0];
    }, [currentJourneyId, journeys]);

    const selectJourney = useCallback((journeyId: string) => {
        sessionStorage.setItem("journeyId", journeyId)
        setCurrentJourneyId(journeyId);
        window.location.reload();
    }, [setCurrentJourneyId]);

    const defaultTheme = createTheme();
    const defaultShadows: ThemeOptions["shadows"] = [...defaultTheme.shadows];

    const theme = createTheme({
        shadows: defaultShadows.map(() => "none") as Shadows,
        palette: {
        primary: {
            main: currentJourney?.uiConfig?.ui ? `#${currentJourney?.uiConfig.ui?.mainPrimaryColor}` : "#000000",
            light: currentJourney?.uiConfig?.ui ? `#${currentJourney?.uiConfig.ui?.lightPrimaryColor}` : "#CCCCCC",
        },
        secondary: {
            main: currentJourney?.uiConfig?.ui ? `#${currentJourney?.uiConfig.ui?.mainSecondaryColor}` : "#bfd2e4",
            light: currentJourney?.uiConfig?.ui ? `#${currentJourney?.uiConfig.ui?.lightSecondaryColor}` : "#f3f6fa",
        },
        },
        typography: {
        fontFamily: ["Roboto", "sans-serif"].join(","),
        },
    });

    return <JourneyContext.Provider value={{currentJourney, journeys, selectJourney}}>
        {
            isError ?
                <Typography color={"error"} padding={1}>
                  {" "}
                  No journey configured for your account. Please contact the system administrator.
                </Typography>
                : currentJourney ? <ThemeProvider theme={theme}>{children}</ThemeProvider> : <LinearProgress />
        }
    </JourneyContext.Provider>
}

export default JourneyContext;
